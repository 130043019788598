import React, { useState } from 'react';
import { Col, Row } from 'antd';
import { Link } from 'react-router-dom';
import { Menu } from 'antd';
import useWebContext from '../hooks/use-webcontext';


const items = [
    {
      label: 'ABOUT',
      key: 'about',
      to: "/meet_dr_lee",
      children: [
        {
          label: 'MEET DR LEE',
          key: 'meet_dr_lee',
          to: "/meet_dr_lee"
        },
        {
          label: 'OUR OFFICE',
          key: 'our_office',
          to: "/our_office"
        }
      ]
    },
    {
      label: 'NEW PATIENTS',
      key: 'new_patients',
      to: "/new_patients",
      children: [
        {
          label: 'NEW PATIENT INFORMATION',
          key: 'new_patient_information',
          to: "/new_patient_information"
        },
        {
          label: 'FREQUENTLY ASKED QUESTIONS',
          key: 'faq',
          to: "/faq"
        }
      ]
    },
    {
        label: 'DENTISTRY',
        key: 'complete_dentistry',
        to: "/complete_dentistry",
        children: [
          {
            label: 'GENERAL SERVICES',
            key: 'general_services',
            to: "/general_services"
          },
          {
            label: 'COMPLETE DENTISTRY',
            key: 'complete_dentistry',
            to: "/complete_dentistry"
          },
          {
            label: 'COSMETIC DENTISTRY',
            key: 'cosmetic_dentistry',
            to: "/cosmetic_dentistry"
          },
          {
            label: 'IMPLANT DENTISTRY',
            key: 'implant_dentistry',
            to: "/implant_dentistry"
          },
          {
            label: 'DENTISTRY BITE ADJUSTMENT',         
            key: 'tmj_therapy',
            to: "/tmj_therapy"
          }
        ]
    },
    {
      label: 'HOME CARE',
      key: 'home_care',
      to: 'home_care',
      children: [
        {
          label: 'AFTER DEEP CLEANING',
          key: 'after_care_deep_cleaning',
          to: "/after_care_deep_cleaning"
        },
        {
          label: 'AFTER FILLINGS',
          key: 'after_care_fillings',
          to: "/after_care_fillings"
        },
        {
          label: 'AFTER CROWN',
          key: 'after_care_crown',
          to: "/after_care_crown"
        },
        {
          label: 'AFTER ROOT CANAL',
          key: 'after_care_root_canal',
          to: "/after_care_root_canal"
        },
        {
          label: 'AFTER DENTURE',
          key: 'after_care_denture',
          to: "/after_care_denture"
        },
        {
          label: 'AFTER WHITENING',
          key: 'after_care_whitening',
          to: "/after_care_whitening"
        },
        {
          label: 'AFTER OCCLUSAL GUARD',
          key: 'after_care_occlusal_guard',
          to: "/after_care_occlusal_guard"
        },
        {
          label: 'BEFORE & AFTER SURGERY',
          key: 'before_after_surgery',
          to: "/before_after_surgery"
        },
        {
          label: 'FOR DRY MOUTH SUFFERS',
          key: 'for_dry_mouth_suffers',
          to: "/for_dry_mouth_suffers"
        }
      ]
    },
    {
        label: 'CONTACT US',
        key: 'for_contact',
        to: "/for_contact"
    }
]

const textStyule = {
  fontFamily: 'Arial',
  fontSize: '16px',
  fontStyle: 'normal',
  letterSpacing: '.06em',
  color: "#05202e" ,
  width: '100%',
  paddingTop:'5px',
  paddingBottom:'21px'
}

function TopMenu() {

    const { currentMenu, setCurrentMenu } = useWebContext();

    const onClick = (e) => {
        console.log('click ', e);
        setCurrentMenu(e.key);
        window.location.href = e.key;
      };

    return (
      <nav >
        <Menu onClick={onClick} mode="horizontal" defaultSelectedKeys={[currentMenu]} style={textStyule} items={items} />
      </nav>
    );

} 

export default TopMenu;